import React from "react";
import "./Reasons.css";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";

const Reasons = () => {
  return (
    <div className="Reasons">
      <div className="left-r">
        <div className="row">
          <div className="colomn">
            <img className="img_11" src={image1} alt="" />
          </div>
          <div className="colomn">
            <img className="img_12" src={image2} alt="" />
            <div className="row">
              <div className="colomn">
                <img className="img_21" src={image3} alt="" />
              </div>
              <div className="colomn">
                <img className="img_22" src={image4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="right-r">
        <span className="title1">some reasons</span>
        <div>
          <span className="stroke-text">Why </span>
          <span>Choose us?</span>
        </div>

        {/* ..... */}
        <dir className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>OVER 140+ EXPERT COACHS</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>1 FREE PROGRAM FOR NEW MEMBER</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>RELIABLE PARTNERS</span>
          </div>
        </dir>
        <dir>
          <span style={{ color: "var(--gray)", fontWeight: "normal" }}>
            Our Partners
          </span>
          <dir className="partners">
            <img src={nb} alt="" />
            <img src={adidas} alt="" />
            <img src={nike} alt="" />
          </dir>
        </dir>
      </div>
    </div>
  );
};

export default Reasons;
